import React, { useState, useEffect, useRef } from 'react';
import BackendService from '../integrations/BackendService';
import Swal from 'sweetalert2';
import { v4 } from "uuid";
import { ref, uploadBytes } from "firebase/storage"
import { imageDb } from '../integrations/FirebaseConfig';
import {
    CurrencyInput,
    Currencies,
    Locales
} from 'input-currency-react';
import { useQuery } from '@tanstack/react-query';

export default function ConfiguracaoPage() {
    const backendService = new BackendService();
    const [selecionados, setSelecionados] = useState({});
    const [valoresFrete, setValoresFrete] = useState({});
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const [config, setConfig] = useState({
        id: null,
        nome: '',
        cnpj: '',
        endereco: {
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
            cep: ''
        },
        configuracaoRestaurante: {
            idLogo: '',
            urlLogo: '',
            categoria: '',
            aberto: false,
            horariosFuncionamento: [],
            delivery: false,
            retirada: false,
            tempoMinimoEntrega: '',
            tempoMaximoEntrega: '',
            tempoMinimoPreparo: '',
            tempoMaximoPreparo: '',
            fidelidadeAtiva: false,
            configuracaoFidelidade: {
                valorMinimo: '',
                quantidadePedidosNecessarios: 0,
                desconto: '',
                tipoDesconto: '',
                validadeEmDias: 0,
            }
        }
    });
    const [tipoCupom, setTipoCupom] = useState('');

    const { data: listaSistemaCidade, isLoading: isListaSistemaCidade, error: errorListaSistemaCidade } = useQuery({
        queryKey: ['buscarSistemaCidade'],
        queryFn: async () => await backendService.buscarSistemaCidade(),
    });

    const { data: listaConfigurada, isLoading: isListaConfigurada, error: errorListaConfigurada } = useQuery({
        queryKey: ['buscarFreteConfigurado'],
        queryFn: async () => await backendService.buscarLocalidadesEntregaRestaurante(),
    });

    useEffect(() => {
        if (listaConfigurada) {
            const initialSelected = {};
            const initialFreteValues = {};

            listaConfigurada.forEach(cidade => {
                initialSelected[cidade.id] = true;
                cidade.regioes.forEach(regiao => {
                    initialSelected[cidade.id] = {
                        ...(initialSelected[cidade.id] || {}),
                        [regiao.id]: true,
                    };
                    initialFreteValues[regiao.id] = String(regiao.valorFrete.toFixed(2) || 0);
                });
            });

            setSelecionados(initialSelected);
            setValoresFrete(initialFreteValues);
        }
    }, [listaConfigurada]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await backendService.buscarConfiguracaoRestaurante();
                const valorFrete = parseFloat(response.configuracaoRestaurante.valorFrete).toFixed(2);

                const configResponse = {
                    ...response,
                    configuracaoRestaurante: {
                        ...response.configuracaoRestaurante,
                        configuracaoFidelidade: {
                            ...response.configuracaoRestaurante.configuracaoFidelidade,
                        },
                        valorFrete: valorFrete
                    }
                };
                setTipoCupom(configResponse.configuracaoRestaurante.configuracaoFidelidade.tipoDesconto);
                setConfig(configResponse);
            } catch (error) {
                console.error('Erro ao buscar configuração:', error.message);
            }
        };
        fetchData();
    }, []);

    const handleCidadeChange = (cidadeId) => {
        setSelecionados((prev) => ({
            ...prev,
            [cidadeId]: !prev[cidadeId],
        }));
    };

    const handleRegiaoChange = (cidadeId, regiaoId) => {
        setSelecionados((prev) => {
            const isSelected = prev[cidadeId]?.[regiaoId];
            const updatedSelecionados = {
                ...prev,
                [cidadeId]: {
                    ...prev[cidadeId],
                    [regiaoId]: !isSelected,
                },
            };

            if (!isSelected) {
                setValoresFrete((prevValores) => {
                    const { [regiaoId]: _, ...rest } = prevValores;
                    return rest;
                });
            }

            return updatedSelecionados;
        });
    };

    const handleFreteChange = (inputElement, maskedValue, value, regiaoId) => {
        setValoresFrete((prev) => ({
            ...prev,
            [regiaoId]: value,
        }));
    };

    const diasDaSemanaMapeados = {
        SEGUNDA: 'segunda-feira',
        TERCA: 'terça-feira',
        QUARTA: 'quarta-feira',
        QUINTA: 'quinta-feira',
        SEXTA: 'sexta-feira',
        SABADO: 'sábado',
        DOMINGO: 'domingo'
    };

    const diasDaSemana = ['SEGUNDA', 'TERCA', 'QUARTA', 'QUINTA', 'SEXTA', 'SABADO', 'DOMINGO'];

    const handleChangeConfiguracao = (e) => {
        const { name, value, type, checked } = e.target;
        setConfig(prevState => ({
            ...prevState,
            configuracaoRestaurante: {
                ...prevState.configuracaoRestaurante,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    };

    const handleTipoCupom = (tipo) => {
        setTipoCupom(tipo);
        setConfig((prevConfig) => ({
            ...prevConfig,
            configuracaoRestaurante: {
                ...prevConfig.configuracaoRestaurante,
                configuracaoFidelidade: {
                    ...prevConfig.configuracaoRestaurante.configuracaoFidelidade,
                    tipoDesconto: tipo
                }
            }
        }));
    };

    const handleDescontoChange = (e, v) => {
        v = v.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.');
        setConfig((prevConfig) => ({
            ...prevConfig,
            configuracaoRestaurante: {
                ...prevConfig.configuracaoRestaurante,
                configuracaoFidelidade: {
                    ...prevConfig.configuracaoRestaurante.configuracaoFidelidade,
                    desconto: v
                }
            }
        }));
    }

    const handleValorMinimoChange = (e, v) => {
        v = v.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.');
        setConfig((prevConfig) => ({
            ...prevConfig,
            configuracaoRestaurante: {
                ...prevConfig.configuracaoRestaurante,
                configuracaoFidelidade: {
                    ...prevConfig.configuracaoRestaurante.configuracaoFidelidade,
                    valorMinimo: v
                }
            }
        }));
    }

    const handleFidelidadeChange = (e, v) => {
        const { name, value, type, checked } = e.target;
        setConfig((prevConfig) => ({
            ...prevConfig,
            configuracaoRestaurante: {
                ...prevConfig.configuracaoRestaurante,
                configuracaoFidelidade: {
                    ...prevConfig.configuracaoRestaurante.configuracaoFidelidade,
                    tipoDesconto: tipoCupom,
                    [name]: type === 'checkbox' ? checked : value
                }
            }
        }));
    };

    const handleSubmit = async () => {
        // Validações Configuração Restaurante
        const validateRestauranteConfig = () => {
            const { configuracaoRestaurante } = config;

            if (!configuracaoRestaurante.delivery) {
                return "O restaurante precisa oferecer a modalidade de delivery.";
            }
            
            if (configuracaoRestaurante.retirada && !configuracaoRestaurante.delivery) {
                return "A modalidade de retirada só pode ser habilitada se o delivery também estiver habilitado.";
            }

            if (
                configuracaoRestaurante.delivery &&
                (!configuracaoRestaurante.tempoMaximoEntrega || isNaN(configuracaoRestaurante.tempoMaximoEntrega) || parseFloat(configuracaoRestaurante.tempoMaximoEntrega) === 0)
            ) {
                return "É necessário ter um tempo máximo de entrega para o delivery.";
            }

            if (
                configuracaoRestaurante.delivery &&
                (!configuracaoRestaurante.tempoMinimoEntrega || isNaN(configuracaoRestaurante.tempoMinimoEntrega) || parseFloat(configuracaoRestaurante.tempoMinimoEntrega) === 0)
            ) {
                return "É necessário ter um tempo mínimo de entrega para o delivery.";
            }

            if (
                configuracaoRestaurante.delivery &&
                configuracaoRestaurante.tempoMinimoEntrega >= configuracaoRestaurante.tempoMaximoEntrega
            ) {
                return "O tempo mínimo de entrega deve ser menor que o tempo máximo.";
            }

            if (
                configuracaoRestaurante.retirada &&
                (!configuracaoRestaurante.tempoMaximoPreparo || isNaN(configuracaoRestaurante.tempoMaximoPreparo) || parseFloat(configuracaoRestaurante.tempoMaximoPreparo) === 0)
            ) {
                return "É necessário ter um tempo máximo de preparo para a retirada.";
            }

            if (
                configuracaoRestaurante.retirada &&
                (!configuracaoRestaurante.tempoMinimoPreparo || isNaN(configuracaoRestaurante.tempoMinimoPreparo) || parseFloat(configuracaoRestaurante.tempoMinimoPreparo) === 0)
            ) {
                return "É necessário ter um tempo mínimo de preparo para a retirada.";
            }

            if (
                configuracaoRestaurante.retirada &&
                configuracaoRestaurante.tempoMinimoPreparo >= configuracaoRestaurante.tempoMaximoPreparo
            ) {
                return "O tempo mínimo de preparo deve ser menor que o tempo máximo.";
            }

            return null;
        };

        // Validações Frete Configurado
        const validateFreteConfig = () => {
            const { configuracaoRestaurante } = config;
            const cidadeComRegiaoSelecionado = Object.values(selecionados).some((regioes) =>
                Object.values(regioes).some((isSelected) => isSelected)
            );

            if (configuracaoRestaurante.delivery && !cidadeComRegiaoSelecionado) {
                return "Selecione pelo menos uma cidade com uma região.";
            }

            const cidadeSemRegiao = Object.entries(selecionados).some(([cidadeId, regioes]) =>
                regioes && !Object.values(regioes).some((isSelected) => isSelected)
            );

            if (cidadeSemRegiao) {
                return "Não é permitido selecionar uma cidade sem regiões.";
            }

            const regiaoComFreteZero = Object.entries(selecionados).some(([cidadeId, regioes]) =>
                Object.entries(regioes).some(([regiaoId, isSelected]) =>
                    isSelected && (parseFloat(valoresFrete[regiaoId]) === 0 || !valoresFrete[regiaoId])
                )
            );

            if (regiaoComFreteZero) {
                return "O valor do frete não pode ser 0.";
            }

            return null;
        };

        // Execução da Lógica
        const restauranteError = validateRestauranteConfig();
        if (restauranteError) {
            Swal.fire({ text: restauranteError, icon: "info" });
            return;
        }

        const freteError = validateFreteConfig();
        if (freteError) {
            Swal.fire({ text: freteError, icon: "error" });
            return;
        }

        if(config.configuracaoRestaurante.fidelidadeAtiva){
            if (!config.configuracaoRestaurante.configuracaoFidelidade.valorMinimo || isNaN(config.configuracaoRestaurante.configuracaoFidelidade.valorMinimo) || parseFloat(config.configuracaoRestaurante.configuracaoFidelidade.valorMinimo) === 0) {
                Swal.fire({
                    text: "O valor minimo de pedido é necessário para a configuração de fidelidade.",
                    icon: "info"
                });
                return;
            }

        
            if (!config.configuracaoRestaurante.configuracaoFidelidade.quantidadePedidosNecessarios || isNaN(config.configuracaoRestaurante.configuracaoFidelidade.quantidadePedidosNecessarios) || parseFloat(config.configuracaoRestaurante.configuracaoFidelidade.quantidadePedidosNecessarios) === 0) {
                Swal.fire({
                    text: "A quantidade de pedidos necessários é necessária para a configuração de fidelidade.",
                    icon: "info"
                });
                return;
            }

            if (!config.configuracaoRestaurante.configuracaoFidelidade.desconto || isNaN(config.configuracaoRestaurante.configuracaoFidelidade.desconto) || parseFloat(config.configuracaoRestaurante.configuracaoFidelidade.desconto) === 0) {
                Swal.fire({
                    text: "O desconto é necessário para a configuração de fidelidade.",
                    icon: "info"
                });
                return;
            }

            if (config.configuracaoRestaurante.configuracaoFidelidade.tipoDesconto !== "PORCENTAGEM" && config.configuracaoRestaurante.configuracaoFidelidade.tipoDesconto !== "VALOR") {
                Swal.fire({
                    text: "O tipo de desconto é necessário para a configuração de fidelidade.",
                    icon: "info"
                });
                return;
            }

            if (!config.configuracaoRestaurante.configuracaoFidelidade.validadeEmDias || isNaN(config.configuracaoRestaurante.configuracaoFidelidade.validadeEmDias) || parseFloat(config.configuracaoRestaurante.configuracaoFidelidade.validadeEmDias) === 0) {
                Swal.fire({
                    text: "O número de dias da validade deve estar preenchido.",
                    icon: "info"
                });
                return;
            }
        }


        Swal.fire({
            title: 'Salvando...',
            text: 'Por favor, aguarde enquanto salvamos sua alteração.',
            allowOutsideClick: false,
            didOpen: () => Swal.showLoading(),
        });

        try {
            // Upload de imagem, se necessário
            const uuid = v4();
            const isNewImage = config.configuracaoRestaurante.urlLogo instanceof Blob || config.configuracaoRestaurante.urlLogo instanceof File;

            if (isNewImage) {
                const imgRef = ref(imageDb, `${config.id}/logo/${uuid}`);
                await uploadBytes(imgRef, config.configuracaoRestaurante.urlLogo);

                setConfig((prevState) => ({
                    ...prevState,
                    configuracaoRestaurante: {
                        ...prevState.configuracaoRestaurante,
                        idLogo: uuid,
                    },
                }));
            }

            // Salvando configurações
            const freteConfig = Object.entries(selecionados).reduce((acc, [cidadeId, regioes]) => {
                const fretesPorRegiao = Object.entries(regioes).reduce((regiaoAcc, [regiaoId, isSelected]) => {
                    if (isSelected) {
                        regiaoAcc.push({ id: regiaoId, valorFrete: parseFloat(valoresFrete[regiaoId]) || 0 });
                    }
                    return regiaoAcc;
                }, []);

                if (fretesPorRegiao.length > 0) {
                    acc.push({ cidadeId, regioes: fretesPorRegiao });
                }
                return acc;
            }, []);

            const { urlLogo, ...rest } = config.configuracaoRestaurante;
            const configuracaoParaEditar = {
                ...config,
                configuracaoRestaurante: {
                    ...rest,
                    idLogo: isNewImage ? uuid : config.configuracaoRestaurante.idLogo,
                },
            };

            await Promise.all([
                backendService.salvarConfiguracaoRestaurante(configuracaoParaEditar.configuracaoRestaurante),
                backendService.salvarFreteConfigurado(freteConfig),
            ]);

            Swal.fire('Sucesso!', 'As alterações foram salvas com sucesso.', 'success').then(() => {
                
            });
        } catch (error) {
            console.error('Erro ao salvar alterações:', error);
            Swal.fire('Erro!', 'Ocorreu um erro ao salvar as alterações.', 'error');
        }
    };


    const hiddenFileInput = useRef(null);
    const handleClick = (event) => {
        hiddenFileInput.current.click();
    };

    const handleChangeLogo = (valor) => {
        const file = valor.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                Swal.fire('Erro!', 'O arquivo selecionado é muito grande. O tamanho máximo permitido é 2 MB.', 'error');
                return;
            }
            setConfig(prevState => ({
                ...prevState,
                configuracaoRestaurante: {
                    ...prevState.configuracaoRestaurante,
                    urlLogo: file
                }
            }));
        }
    }

    const renderImage = () => {
        if (typeof config.configuracaoRestaurante.urlLogo === 'string') {
            // Considera como URL
            return (
                <img
                    src={config.configuracaoRestaurante.urlLogo}
                    alt="Imagem selecionada"
                    className="mb-4 rounded-full cursor-pointer"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    onClick={handleClick}
                />
            );
        } else if (config.configuracaoRestaurante.urlLogo instanceof Blob || config.configuracaoRestaurante.urlLogo instanceof File) {
            // Considera como Blob ou File
            return (
                <img
                    src={URL.createObjectURL(config.configuracaoRestaurante.urlLogo)}
                    alt="Imagem selecionada"
                    className="mb-4 rounded-full cursor-pointer"
                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    onClick={handleClick}
                />
            );
        }
    };

    const handleChangePreco = (campo, valor) => {
        valor = valor.replace(/\s/g, '').replace('R$', '').replace('.', '').replace(',', '.');
        setConfig({
            ...config,
            configuracaoRestaurante: {
                ...config.configuracaoRestaurante,
                ['valorFrete']: valor
            }
        });
    };

    const handleChangeDiaFuncionamento = (e, diaSemana) => {
        const { checked } = e.target;

        setConfig((prevConfig) => {
            // Garantindo que `horariosFuncionamento` seja um array
            const horariosFuncionamento = Array.isArray(prevConfig.configuracaoRestaurante.horariosFuncionamento)
                ? [...prevConfig.configuracaoRestaurante.horariosFuncionamento]
                : [];

            if (checked) {
                // Se o dia foi marcado, adicionar horário padrão (ex: 09:00 - 18:00)
                horariosFuncionamento.push({
                    diaSemana: diaSemana,
                    horarioAbertura: "09:00",
                    horarioFechamento: "18:00",
                });
            } else {
                // Se o dia foi desmarcado, remover o horário correspondente
                const index = horariosFuncionamento?.findIndex((horario) => horario.diaSemana === diaSemana);
                if (index !== -1) {
                    horariosFuncionamento?.splice(index, 1);
                }
            }

            return {
                ...prevConfig,
                configuracaoRestaurante: {
                    ...prevConfig.configuracaoRestaurante,
                    horariosFuncionamento: horariosFuncionamento,
                },
            };
        });
    };


    // Função para atualizar o horário de abertura ou fechamento
    const handleChangeHorario = (e, diaSemana, tipo) => {
        const { value } = e.target;

        setConfig((prevConfig) => {
            const horariosFuncionamento = prevConfig.configuracaoRestaurante.horariosFuncionamento?.map((horario) => {
                if (horario.diaSemana === diaSemana) {
                    if (tipo === 'abertura') {
                        return { ...horario, horarioAbertura: value };
                    } else if (tipo === 'fechamento') {
                        return { ...horario, horarioFechamento: value };
                    }
                }
                return horario;
            });

            return {
                ...prevConfig,
                configuracaoRestaurante: {
                    ...prevConfig.configuracaoRestaurante,
                    horariosFuncionamento: horariosFuncionamento,
                },
            };
        });
    };


    return (
        <div className="flex flex-col h-screen p-4">
            <div className='flex gap-4 overflow-y-auto'>
                <div className='flex flex-col '>
                    <div className="text-center flex flex-row items-center">
                        {!config.configuracaoRestaurante.urlLogo && (
                            <button
                                className="button-upload rounded-full shadow-lg p-2"
                                onClick={handleClick}
                                style={{ width: '100px', height: '100px' }} // Definindo a largura e altura para torná-lo um círculo
                            >
                                LOGO
                            </button>
                        )}
                        {config.configuracaoRestaurante.urlLogo && (
                            <div className="flex items-center justify-center">
                                {renderImage()}
                            </div>
                        )}
                        <span className="ml-5 text-2xl font-bold text-center">{config.nome}</span>
                        <input
                            type="file"
                            onChange={handleChangeLogo}
                            ref={hiddenFileInput}
                            style={{ display: "none" }}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2 font-bold">Status estabelecimento:</label>
                        <input
                            type="checkbox"
                            name="aberto"
                            checked={config.configuracaoRestaurante.aberto}
                            onChange={handleChangeConfiguracao}
                            className="form-checkbox h-5 w-5 text-blue-600"
                        />
                        <span className="ml-2 text-gray-700">Aberto</span>
                    </div>

                    <div>
                        <label className="block text-gray-700 mb-2 font-bold">Informação Pix</label>

                        {/* Mensagem PIX */}
                        <input
                            type="text"
                            name="mensagemPix"
                            value={config.configuracaoRestaurante.mensagemPix}
                            onChange={handleChangeConfiguracao}
                            className="mt-2 ml-3 p-2 border rounded-lg w-full"
                            placeholder="Digite a mensagem de instrução do pagamento via PIX"
                        />

                        {/* Chave PIX */}
                        <input
                            type="text"
                            name="chavePix"
                            value={config.configuracaoRestaurante.chavePix}
                            onChange={handleChangeConfiguracao}
                            className="mt-2 ml-3 p-2 border rounded-lg w-full"
                            placeholder="Digite a Chave PIX"
                        />

                        {/* Nome Pix */}
                        <input
                            type="text"
                            name="nomePix"
                            value={config.configuracaoRestaurante.nomePix}
                            onChange={handleChangeConfiguracao}
                            className="mt-2 ml-3 p-2 border rounded-lg w-full"
                            placeholder="Digite o nome do recebedor do PIX"
                        />

                        {/* WhatsApp */}
                        <input
                            type="text"
                            name="whatsapp"
                            value={config.configuracaoRestaurante.whatsapp}
                            onInput={(e) => e.target.value = e.target.value.replace(/\D/g, '')}
                            onChange={handleChangeConfiguracao}
                            className="mt-2 ml-3 p-2 border rounded-lg w-full"
                            placeholder="Digite seu Whatsapp DDD+número"
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 mb-2 font-bold">Tipo de encomenda:</label>

                        <div className="flex items-center gap-4">
                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="delivery"
                                    checked={config.configuracaoRestaurante.delivery}
                                    onChange={handleChangeConfiguracao}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <span className="ml-2 text-gray-700">Delivery</span>
                            </div>

                            <div className="flex items-center">
                                <input
                                    type="checkbox"
                                    name="retirada"
                                    checked={config.configuracaoRestaurante.retirada}
                                    onChange={handleChangeConfiguracao}
                                    className="form-checkbox h-5 w-5 text-blue-600"
                                />
                                <span className="ml-2 text-gray-700">Retirada</span>
                            </div>
                        </div>

                        {config.configuracaoRestaurante.delivery && (
                            <div className="flex flex-col mt-4">
                                <label className="block text-gray-700 mb-2 font-bold">Tempo de entrega (minutos):</label>
                                <div className="flex gap-4 ">
                                    <div>
                                        <label className="block text-gray-600">Tempo Mínimo:</label>
                                        <input
                                            type="number"
                                            name="tempoMinimoEntrega"
                                            value={config.configuracaoRestaurante.tempoMinimoEntrega || ''}
                                            onChange={handleChangeConfiguracao}
                                            className="mt-2 p-2 border rounded-lg "
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-600">Tempo Máximo:</label>
                                        <input
                                            type="number"
                                            name="tempoMaximoEntrega"
                                            value={config.configuracaoRestaurante.tempoMaximoEntrega || ''}
                                            onChange={handleChangeConfiguracao}
                                            className="mt-2 p-2 border rounded-lg"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {config.configuracaoRestaurante.retirada && (
                            <div className="mt-4">
                                <label className="block text-gray-700 mb-2 font-bold">Tempo de preparo para retirada (minutos):</label>
                                <div className="flex gap-4">
                                    <div>
                                        <label className="block text-gray-600">Tempo Mínimo:</label>
                                        <input
                                            type="number"
                                            name="tempoMinimoPreparo"
                                            value={config.configuracaoRestaurante.tempoMinimoPreparo || ''}
                                            onChange={handleChangeConfiguracao}
                                            className="mt-2 p-2 border rounded-lg"
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-gray-600">Tempo Máximo:</label>
                                        <input
                                            type="number"
                                            name="tempoMaximoPreparo"
                                            value={config.configuracaoRestaurante.tempoMaximoPreparo || ''}
                                            onChange={handleChangeConfiguracao}
                                            className="mt-2 p-2 border rounded-lg"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-4">
                    <label className='block text-gray-700 mb-2 font-bold'>Programa de Fidelidade</label>
                    <div className="flex items-center gap-4">
                        <div className="flex items-center"> <input
                            type="checkbox"
                            name="fidelidadeAtiva"
                            checked={config.configuracaoRestaurante.fidelidadeAtiva}
                            onChange={handleChangeConfiguracao}
                            className='form-checkbox h-5 w-5 text-blue-600'
                        />
                            <span className="ml-2 text-gray-700">Ativar Programa de Fidelidade</span></div></div>

                </div>

                {config.configuracaoRestaurante.fidelidadeAtiva && (
                    <div><div>
                        <label className="ml-2 text-gray-700">
                            Valor Mínimo para Compra:
                            <CurrencyInput
                                        options={{
                                            precision: 2,
                                            style: "currency",
                                            locale: Locales["Portuguese (Brazil)"], // Format Type
                                            i18nCurrency: Currencies["Brazilian Real"] // Symbol
                                        }}
                                        value={parseFloat(config.configuracaoRestaurante.configuracaoFidelidade.valorMinimo).toFixed(2).toString()}
                                        onChangeEvent={handleValorMinimoChange}
                                        className="mt-2 ml-3 p-2 border rounded-lg "
                                    />
                        </label></div>
                        <div>
                            <label className="ml-2 text-gray-700">
                                Quantidade de Pedidos Necessárias:
                                <input
                                    type="number"
                                    name="quantidadePedidosNecessarios"
                                    value={config.configuracaoRestaurante.configuracaoFidelidade.quantidadePedidosNecessarios}
                                    onChange={handleFidelidadeChange}
                                    className="mt-2 ml-3 p-2 border rounded-lg "
                                />
                            </label>
                        </div>

                        <div>
                            <label className="ml-2 text-gray-700">
                                Numero de dias de validade do cupom:
                                <input
                                    type="number"
                                    name="validadeEmDias"
                                    value={config.configuracaoRestaurante.configuracaoFidelidade.validadeEmDias}
                                    onChange={handleFidelidadeChange}
                                    className="mt-2 ml-3 p-2 border rounded-lg "
                                />
                            </label>
                        </div>
                        <div className="flex items-center m-2">
                            <input
                                type="checkbox"
                                checked={tipoCupom === "PORCENTAGEM"}
                                onChange={() => handleTipoCupom("PORCENTAGEM")}
                                className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            <span className="ml-2 text-gray-700">Porcentagem</span>
                            <input
                                type="checkbox"
                                checked={tipoCupom === "VALOR"}
                                onChange={() => handleTipoCupom("VALOR")}
                                className="form-checkbox h-5 w-5 ml-2 text-blue-600"
                            />
                            <span className="ml-2 text-gray-700">Valor</span>
                        </div>
                        <div>
                            <label className="ml-2 text-gray-700">
                                Desconto:
                                {tipoCupom === "VALOR" ? (
                                    <CurrencyInput
                                        options={{
                                            precision: 2,
                                            style: "currency",
                                            locale: Locales["Portuguese (Brazil)"], // Format Type
                                            i18nCurrency: Currencies["Brazilian Real"] // Symbol
                                        }}
                                        placeholder="Desconto"
                                        value={parseFloat(config.configuracaoRestaurante.configuracaoFidelidade.desconto).toFixed(2).toString()}
                                        onChangeEvent={handleDescontoChange}
                                        className="mt-2 ml-3 p-2 border rounded-lg "
                                    />
                                ) : (
                                    <input
                                        id="desconto"
                                        type="number"
                                        step="0.01"
                                        value={config.configuracaoRestaurante.configuracaoFidelidade.desconto}
                                        name="desconto"
                                        onChange={handleFidelidadeChange}
                                        placeholder="Desconto"
                                        className="mt-2 ml-3 p-2 border rounded-lg "
                                    />
                                )}
                            </label>
                        </div>
                    </div>
                )}

                    <div className="mb-4">
                        <label className="block text-gray-700 font-bold">Horários de Funcionamento:</label>

                        {diasDaSemana.map((dia) => {
                            const horarioFuncionamento = config.configuracaoRestaurante.horariosFuncionamento?.find(
                                (horario) => horario.diaSemana === dia
                            );

                            const checked = Boolean(horarioFuncionamento);

                            return (
                                <div key={dia} className="flex items-center mb-2">
                                    <label className="inline-flex items-center w-52">
                                        <input
                                            type="checkbox"
                                            name={dia}
                                            checked={checked}
                                            onChange={(e) => handleChangeDiaFuncionamento(e, dia)}
                                            className="form-checkbox h-5 w-5 text-blue-600"
                                        />
                                        <span className="ml-2 text-gray-700">{diasDaSemanaMapeados[dia]}</span>
                                    </label>

                                    {checked && (
                                        <div className="flex ml-4 gap-4">
                                            <label>
                                                Abertura:
                                                <input
                                                    type="time"
                                                    name={`${dia}-abertura`}
                                                    value={horarioFuncionamento?.horarioAbertura || '09:00'}
                                                    onChange={(e) => handleChangeHorario(e, dia, 'abertura')}
                                                    className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out text-gray-700"
                                                />
                                            </label>
                                            <label>
                                                Fechamento:
                                                <input
                                                    type="time"
                                                    name={`${dia}-fechamento`}
                                                    value={horarioFuncionamento?.horarioFechamento || '18:00'}
                                                    onChange={(e) => handleChangeHorario(e, dia, 'fechamento')}
                                                    className="ml-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out text-gray-700"
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {config.configuracaoRestaurante.delivery && (
                    <div>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Configuração Frete</h2>

                        {listaSistemaCidade?.map((cidade) => (
                            <div key={cidade.id} className="mb-2">
                                <label className="inline-flex items-center space-x-2 mb-2">
                                    <input
                                        type="checkbox"
                                        checked={!!selecionados[cidade.id]}
                                        onChange={() => handleCidadeChange(cidade.id)}
                                        className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                    <span className="text-gray-700 font-medium">{cidade.nome}</span>
                                </label>

                                {selecionados[cidade.id] && (
                                    <div className="flex flex-col pl-6 gap-2">
                                        {cidade.regioes.map((regiao) => (
                                            <div key={regiao.id} className="flex items-center space-x-4">
                                                <label className="inline-flex items-center space-x-2">
                                                    <input
                                                        type="checkbox"
                                                        checked={!!selecionados[cidade.id]?.[regiao.id]}
                                                        onChange={() => handleRegiaoChange(cidade.id, regiao.id)}
                                                        className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                    />
                                                    <span className="text-gray-600">{regiao.nome}</span>
                                                </label>
                                                {selecionados[cidade.id]?.[regiao.id] && (
                                                    <div>
                                                        <label className="flex flex-col text-gray-700">Valor do Frete:</label>
                                                        <CurrencyInput
                                                            options={{
                                                                decimalsLimit: 2,
                                                                style: "currency",
                                                                locale: Locales["Portuguese (Brazil)"],
                                                                i18nCurrency: Currencies["Brazilian Real"],
                                                            }}
                                                            placeholder="Preço"
                                                            value={valoresFrete[regiao.id] ? valoresFrete[regiao.id] : ''}
                                                            onChangeEvent={(inputElement, maskedValue, value) => handleFreteChange(inputElement, maskedValue, value, regiao.id)}
                                                            className="px-3 py-2 border rounded-lg"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>)}

            </div>
            <div>
                <button
                    onClick={handleSubmit}
                    type="submit"
                    className="w-4/12 bg-blue-500 text-white p-2 rounded-md mt-4 hover:bg-blue-600"
                >
                    Salvar
                </button>
            </div>
        </div>

    );
}
